import React, { FC, useMemo, useState } from 'react';
import Link from 'next/link';
import { Button } from '@/components/buttons/Button';
import { Text } from '@/components/Text';
import ThotBotChatImage from '@/assets/icons/thot-bot-chat.svg';
import CreationIcon from '@/assets/icons/creation-icon.svg';
import CharacterGalleryIcon from '@/assets/icons/char-gallery-icon.svg';
import CameraPortraitIcon from '@/assets/icons/camera-portrait.svg';
import GalleryIcon from '@/assets/icons/gallery-icon.svg';
import { GemIcon } from 'lucide-react';
import { MenuArrowsIcon } from '@/assets/icons/MenuArrows';
import { UserIcon } from '@/assets/icons/UserIcon';
import { cn } from '@/lib/utils';
import { SocialMediaButton } from '@/components/buttons/SocialMediaButton';
import discordIcon from '@/assets/social-icons/discord-icon.svg';
import emailIcon from '@/assets/social-icons/email-icon.svg';
import { CustomLink } from '@/components/CustomLink';
import { useRouter } from 'next/router';
import { openModal, closeModal } from '@/components/BaseModal';
import { IChatQuotaConfig } from '@/types/chat';
import { Modals } from '@/types/modals';
import { ResetChatModal } from '@/components/modals/ResetChat';
import { DeleteChatModal } from '@/components/modals/DeleteChat';
import { UnlockMessagesResetModal } from '@/components/modals/UnlockMessagesReset';
import { useIsAuthenticated } from '@/hooks/useIsAuthenticated';
import SidebarChatItem from './SidebarChatItem';
import { useCharactersStore } from '@/stores/characters';
import { useUserStore } from '@/stores/user';
import { useAppStore } from '@/stores/app';
import useIsMobile from '@/hooks/useIsMobile';
import { FeedbackButton } from '@/components/buttons/FeedbackButton';
import posthog from 'posthog-js';

export type SidebarProps = {
  isOpen: boolean;
  toggleSidebar: () => void;
};

export const Sidebar: FC<SidebarProps> = ({ isOpen, toggleSidebar }) => {
  const { userStats } = useUserStore();
  const [actionTarget, setActionTarget] = useState<number>();
  const { push } = useRouter();
  const { recentChats, recentChatsLoading } = useCharactersStore(s => ({
    recentChats: s.recentChats,
    recentChatsLoading: s.recentChatsLoading,
  }));
  const isSignedIn = useIsAuthenticated();
  const router = useRouter();
  const activeChatId = router.asPath.startsWith('/chat/') ? Number(router.query.id) : null;
  const isMobile = useIsMobile();
  const [quotaConfig, setQuotaConfig] = useState<IChatQuotaConfig>();
  const { setSignInUiType, setIsSignInModalVisible } = useAppStore(s => s);

  function onDelete(id: number) {
    setActionTarget(id);
    openModal(Modals.DeleteChat);
  }

  function onReset(id: number) {
    setActionTarget(id);
    if (isMobile) {
      toggleSidebar();
    }
    openModal(Modals.ResetChat);
  }

  const handleNavClick = (path: string) => {
    if (isMobile) {
      toggleSidebar();
    }
    push(path);
  };

  const onClickSignIn = () => {
    setSignInUiType('signinUI');
    setIsSignInModalVisible(true);
    openModal(Modals.SignIn);
    window.gtag && window.gtag('event', 'signin_attempt', {'location': 'sidebar'});
    posthog.capture('signin_attempt', {'location': 'sidebar'});
  }

  const onShowGallery = () => {
    if (isSignedIn) {
      handleNavClick('/images/collection');
    }
    else {
      onClickSignIn();
    }
  }

  const onMyCharacters = () => {
    handleNavClick('/creations');
  }

  const onCreateImages = () => {
    if (isSignedIn) {
      handleNavClick('/images/generate');
    }
    else {
      onClickSignIn();
    }
  }

  const onViewSubscription = () => {
    if (isSignedIn) {
      handleNavClick('/premium');
    }
    else {
      onClickSignIn();
    }
  }

  const onResetQuotaHit = (config: IChatQuotaConfig) => {
    setQuotaConfig(config);
    openModal(Modals.UnlockMessageReset);
  }

  const onResetComplete = () => {
    setQuotaConfig(undefined);
    closeModal(Modals.ResetChat);
  }

  const sortedChats = useMemo(() => {
    return [...recentChats].sort(
      (a, b) => new Date(b.last_interaction_time).getTime() - new Date(a.last_interaction_time).getTime(),
    );
  }, [recentChats]);

  return (
    <>
      <aside
        className={cn(
          'h-[100dvh] overflow-hidden transition-all ease-out duration-300 z-20 lg:z-0 w-80 md:w-auto absolute lg:static',
          isOpen ? 'translate-x-0 md:translate-x-0' : '-translate-x-full md:translate-x-0',
        )}
      >
        <div className="w-full h-full flex">
          <div
            className={cn(
              'overflow-hidden transition-all ease-out duration-300 bg-black-100',
              isOpen ? 'w-full md:max-w-80 translate-x-0' : 'md:max-w-0 -translate-x-full',
            )}
          >
            <div className="h-full flex flex-col overflow-hidden w-full md:w-80">
              <div className="flex w-full justify-between items-center py-2.5 border-b border-black-200 px-5 md:px-8">
                <Link href="/" aria-label="go to home page">
                  <ThotBotChatImage />
                </Link>
                <Button
                  onClick={toggleSidebar}
                  className="w-auto z-0"
                  wrapperClass="py-[12px] w-[50px] h-[50px] border border-white bg-black-200 group-active/button:bg-white p-[13px]"
                >
                  <MenuArrowsIcon className="-ml-[3px]" pathClassName="stroke-white group-active/button:stroke-black" />
                </Button>
              </div>
              <div className="px-5 md:px-8 mt-2.5 mb-4">
                <Button
                  onClick={() => handleNavClick('/')}
                  className="w-full mb-2"
                  wrapperClass="py-[12px] border border-white bg-black-200 group-active/button:bg-white p-[13px] items-left justify-start"
                  // wrapperClass="py-[12px] border border-black bg-blue-gr group-active/button:bg-black-gr p-[13px]"
                >
                  <CharacterGalleryIcon className="ml-1 mr-2" />
                  <Text textColor="white" variant="btn-1">
                    Explore
                  </Text>
                </Button>

                <Button
                  onClick={onMyCharacters}
                  className="w-full mb-2"
                  wrapperClass="py-[12px] border border-white bg-black-200 group-active/button:bg-white p-[13px] items-left justify-start"
                  // wrapperClass="py-[12px] border border-black bg-blue-gr group-active/button:bg-black-gr p-[13px] items-left justify-start"
                >
                  <CreationIcon className="ml-2 mr-3" width={20} height={20} />
                  <Text textColor="white" variant="btn-1">
                    My AI
                  </Text>
                </Button>

                <Button
                  onClick={onShowGallery}
                  className="w-full mb-2"
                  wrapperClass="py-[12px] border border-white bg-black-200 group-active/button:bg-white p-[13px] items-left justify-start"
                  // wrapperClass="py-[12px] border border-black bg-blue-gr group-active/button:bg-black-gr p-[13px] items-left justify-start"
                >
                  <GalleryIcon className="ml-2 mr-3" width={20} height={20} />
                  <Text textColor="white" variant="btn-1">
                    Gallery
                  </Text>
                </Button>


                <Button
                  onClick={onCreateImages}
                  className="w-full mb-2"
                  wrapperClass="py-[12px] border border-white bg-black-200 group-active/button:bg-white p-[13px] items-left justify-start"
                  // wrapperClass="py-[12px] border border-black bg-blue-gr group-active/button:bg-black-gr p-[13px] items-left justify-start"
                >
                  <CameraPortraitIcon className="ml-2 mr-3" width={20} height={20} />
                  <Text textColor="white" variant="btn-1">
                    Generate Image
                  </Text>
                </Button>

                {userStats && userStats.subscription?.status !== 'active' && (
                  <Button
                    onClick={onViewSubscription}
                    className="w-full mb-2"
                    wrapperClass="py-[12px] border border-white bg-pink-gr group-active/button:bg-white p-[13px] items-left justify-start"
                    // wrapperClass="py-[12px] border border-black bg-blue-gr group-active/button:bg-black-gr p-[13px] items-left justify-start"
                  >
                    <GemIcon className="ml-2 mr-3 text-white" width={20} height={20} />
                    <Text textColor="white" variant="btn-1">
                      Premium
                    </Text>
                  </Button>
                )}

              </div>
              <div className="w-full flex-1 px-4 overflow-x-hidden custom-scrollbar">
                <div className="flex pb-2.5">
                  {isSignedIn && (
                    <div className="flex flex-col w-full">
                      {recentChatsLoading && !sortedChats?.length ? (
                        Array.from(new Array(3)).map((el, i) => (
                          <div key={i} className="skeleton w-full h-[66px] mb-2"></div>
                        ))
                      ) : sortedChats.length > 0 ? (
                        sortedChats.map((item, i) => (
                          <SidebarChatItem
                            key={item.character_id}
                            item={item}
                            isActive={activeChatId === item.character_id}
                            onClick={() => handleNavClick(`/chat/${item.character_id}`)}
                            onDelete={onDelete}
                            onReset={onReset}
                          />
                        ))
                      ) : (
                        <div className="flex w-full h-full justify-center mt-2">
                          <Text className="text-white/[.8]" variant="h6">
                            Your chat history will be here
                          </Text>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col px-5 md:px-8 pt-4 pb-3 border-t border-black-200">
                {/* {isSignedIn && userStats?.login_email && !userStats?.email_verified && (
                  <Text className="text-red-500 text-center text-sm mx-2 mb-2" variant={'btn-1'}>
                    {userStats?.login_email} Unverified
                  </Text>
                )}
                {isSignedIn && (
                  <Button
                    onClick={() => handleNavClick('/profile')}
                    className={`w-full mb-6`}
                    wrapperClass="py-[12px] border border-white bg-black-200 group-active/button:bg-white p-[13px] items-left justify-start"
                  >
                    <UserIcon
                      className="mr-2 mr-3"
                      pathClassName="stroke-white transition-all duration-150 group-active/button:stroke-black"
                    />
                    <Text
                      className="text-white group-active/button:text-black transition-all duration-150"
                      variant="btn-1"
                    >
                      Profile
                    </Text>
                  </Button>
                )} */}

                {/* {!isSignedIn && (
                  <Button
                    onClick={onClickSignIn}
                    className={`w-full mb-6`}
                    wrapperClass="py-[12px] border border-black bg-blue-gr group-active/button:bg-black-gr p-[13px]"
                  >
                    <Text textColor="white" variant="btn-1">
                      Sign in
                    </Text>
                  </Button>
                )} */}

                <div className="flex justify-center mb-4 lg:justify-start">
                  <SocialMediaButton
                    className="social-btn mr-3"
                    onClick={() => window.open('https://discord.gg/thotbots', '_blank')}
                    Icon={discordIcon}
                  />
                  <SocialMediaButton
                    className="social-btn mr-3"
                    onClick={() => window.open('mailto:support@thotchat.ai', '_blank')}
                    Icon={emailIcon}
                  />
                  {/* <FeedbackButton 
                    className="social-btn m"
                    onClick={() => openModal(Modals.Feedback)} Icon={discordIcon}>
                    Feedback
                  </FeedbackButton> */}
                </div>
                <div className="flex justify-between">
                  <CustomLink href="/privacy-policy" title="Privacy Policy" onClick={() => handleNavClick('/privacy-policy')} />
                  <CustomLink href="/complaint-policy" title="Complaint Policy" onClick={() => handleNavClick('/complaint-policy')} />
                  <CustomLink href="/terms-of-service" title="Terms of Service" onClick={() => handleNavClick('/terms-of-service')} />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="hidden xl:flex items-center absolute ml-8 mt-2.5">
            <MenuButton className={isOpen ? 'hidden' : ''} onClick={toggleSidebar} />
          </div> */}
        </div>
      </aside>
      {/* no target in modal! */}
      <DeleteChatModal characterId={actionTarget} />
      <ResetChatModal 
          characterId={actionTarget} 
          onQuotaHit={onResetQuotaHit} 
          onComplete={onResetComplete}/>
      {actionTarget && (
        <UnlockMessagesResetModal 
          config={quotaConfig}
          characterId={actionTarget} 
          onComplete={onResetComplete}
          />
      )}
    </>
  );
};
