import { cn } from '@/lib/utils';
import { useCharactersStore } from '@/stores/characters';
import AdultIcon from '@/assets/icons/adult-icon.svg';
import { Text } from '@/components/Text';
import { Image, BookHeart, MessagesSquare, UserPlus, Infinity, AudioLines, Ellipsis } from 'lucide-react';
import { Modals } from '@/types/modals';
import { IChatQuotaConfig } from '@/types/chat';
import { showToast } from '@/stores/app';
import { ToastType } from '@/types/toast';
import { ICharacter } from '@/models/characters';
import { IQuotaConfig } from '@/types/quota';
import { UnlockBaseModal } from '@/components/modals/UnlockBaseModal';
import posthog from 'posthog-js';

type Props = {
  idOverride?: string;
  className?: string;
  onComplete: () => Promise<void>;
  closeOnSuccess?: boolean;
  config?: IChatQuotaConfig;
  character: ICharacter;
};

export const UnlockMessagesModal: React.FC<Props> = ({ idOverride, className, config, onComplete, character, closeOnSuccess = false }) => {
  const { unlockMessages } = useCharactersStore(s => ({ unlockMessages: s.unlockMessages }));
  const quotaConfig : IQuotaConfig = {
    text: config?.imageRequest ? 'Wanna see private images?' : 'Wanna keep chatting?',
    subtitle: config?.subscription_required ? 'Become premium to continue chatting' : 'Send me some 💎 so we can continue the fun',
    success_text: 'Message me now',
    message_locked: config?.message_locked || false,
    button_text: config?.subscription_required ? 'Upgrade to Premium' : 'Start chatting ',
    currency:  config?.subscription_required ? null : "gem",
    cost: config?.subscription_required ? null : config?.gem_cost || 0,
    subscription_required: config?.subscription_required || false,
  };

  async function onUnlockMessages() : Promise<boolean> {
    if (!config) {
      showToast('Unable to unlock, cost unknown', ToastType.ERROR);
      return false;
    }

    const success = await unlockMessages(config, character.id);
    if (success) {
      window.gtag && window.gtag('event', 'unlock_messages_succeess');
      posthog.capture('unlock_messages_success');
    } 
    return success;
  }

  const renderUnlockFeatureHeader = () => {
    if (config?.imageRequest) {
      return (
        <div>
        <Text className="items-center text-white" variant="h3">
          <span className="inline-flex items-center align-center"><Image className="w-6 h-6 mb-1 mr-2 text-pink" /> AI photo sending</span>
        </Text>
      </div>
        
      );
    } else {
      return (
        <Text className="items-center text-white" variant="h3">
          <span className="inline-flex items-center align-center"><MessagesSquare className="w-6 h-6 mb-1 mr-2 text-pink" /> Unlimited messages</span>
        </Text>
      );
    }
  }

  return (
    <UnlockBaseModal
      className={cn("h-full overflow-x-hidden overflow-y-scroll sm:h-auto sm:overflow-auto", className)} 
      idOverride={idOverride || Modals.UnlockMessage}
      config={quotaConfig}
      character={character}
      onUnlockAction={onUnlockMessages}
      onComplete={onComplete}
      closeOnSuccess={closeOnSuccess}
      onRenderUnlockFeatureHeader={renderUnlockFeatureHeader}
      />     
  );
};
