import { useRouter } from 'next/router';
import { PropsWithChildren } from 'react';
import { useCountdownTimer } from '@/hooks/useCountdownTimer';
import Image from 'next/image';
import { cn } from '@/lib/utils';
import dotsAnimation from '@/assets/animations/dots.json';
import { Modals } from '@/types/modals';
import { Text } from '@/components/Text';
import { UnlockButton } from '@/components/buttons/UnlockButton';
import { Player } from '@lottiefiles/react-lottie-player';
import { BaseModal, closeModal } from '../BaseModal';
import { IQuotaConfig } from '@/types/quota';
import { useState } from 'react';
import { ICharacter, SimplifiedCharacter } from '@/models/characters';
import { Button } from '@/components/buttons/Button';
import CloseIcon from '@/assets/icons/close-icon.svg';
import { BotMessageSquare, Ear, AudioLines, UserPlus, ImagePlus, MessagesSquare, Ellipsis, Infinity, BookHeart } from 'lucide-react';
import AdultIcon from '@/assets/icons/adult-icon.svg';
import { SkeletonImage } from '../SkeletonImage';

type Props = PropsWithChildren<{
  idOverride?: string;
  className?: string;
  closeOnSuccess?: boolean;
  config?: IQuotaConfig;
  character: ICharacter | SimplifiedCharacter;
  onUnlockAction: () => Promise<boolean>;
  onComplete: () => Promise<void>;
  onRenderUnlockFeatureHeader?: () => React.ReactNode;
  onRenderBenefit?: () => React.ReactNode;
}>;

export const UnlockBaseModal: React.FC<Props> = ({ children, idOverride, className, config, character, closeOnSuccess = false, onUnlockAction, onComplete, onRenderBenefit, onRenderUnlockFeatureHeader }) => {
  const [state, setState] = useState<'default' | 'success'>('default');
  const [loading, setLoading] = useState(false);

  const { timeLeft, timeStr } = useCountdownTimer(config?.remaining_time ?? 0, onClose);
  const { push } = useRouter();

  async function onSuccess() {
    if (closeOnSuccess) {
      onClose();
    }
    else {
      setState('success');
    }
    await onComplete();
  }

  function onError() {
  }

  async function onUnlock() {
    if (config?.subscription_required) {
      push('/premium');
      return true;
    }

    setLoading(true);
    const success = await onUnlockAction();
    if (success) {
      await onSuccess();
    }
    else {
      onError();
    }
    setLoading(false);
  }

  function onClose() {
    setState('default');
    closeModal(idOverride || Modals.UnlockMessage);
  }

  const renderSubscriptionBenefit = () => {
    return (
      <div className="flex flex-col w-full mt-1">
        <div className="flex flex-col w-full items-left justify-left mt-6 mb-3">
          <Text className="text-white opacity-80 w-11/12 items-left text-2xl leading-[1.5]" variant="h3">
            Upgrade to Unlock 
          </Text>
          {onRenderUnlockFeatureHeader ? onRenderUnlockFeatureHeader() : null}
        </div>

        {onRenderBenefit ? onRenderBenefit() : (
          <div>
            <Text className="text-white opacity-80 w-11/12 mt-1 mb-3" variant="h6">
              With Premium you get access to:
            </Text>

            <div className="flex flex-row w-full items-center mb-2">
              <UserPlus className="w-6 h-6 mr-2 text-pink" />
              <Text className="text-white w-11/12 mt-1 font-normal" variant="h5">
                Create your own AI Girlfriends
              </Text>
            </div>

            <div className="flex flex-row w-full items-center mb-2">
              <Infinity className="w-6 h-6 mr-2 text-pink" />
              <Text className="text-white w-11/12 mt-1 font-normal" variant="h5">
                Unlimited uncensored chat
              </Text>
            </div>

            <div className="flex flex-row w-full items-center mb-2">
              <AdultIcon className="w-6 h-6 mr-2 text-pink" />
              <Text className="text-white w-11/12 mt-1 font-normal" variant="h5">
                Spicy photos 🌶️
              </Text>
            </div>

            <div className="flex flex-row w-full items-center mb-2">
              <AudioLines className="w-6 h-6 mr-2 text-pink" />
              <Text className="text-white w-11/12 mt-1 font-normal" variant="h5">
                Sexy voice messages
              </Text>
            </div>

            <div className="flex flex-row w-full items-center mb-2">
              <BookHeart className="w-6 h-6 mr-2 text-pink" />
              <Text className="text-white w-11/12 mt-1 font-normal" variant="h5">
                Over 1000 FREE 💎/month
              </Text>
            </div>

            {/* <div className="flex flex-row w-full items-center">
              <Ellipsis className="w-6 h-6 mr-2 text-pink" />
              <Text className="text-white w-11/12 mt-1 font-normal" variant="h5">
                And more!
              </Text>
            </div> */}

          </div>
        )}
      </div>
    )
  }

  const renderSuccess = () => {
    return (
      <div className="absolute bottom-0 inset-x-0 flex flex-col items-center justify-center p-5 text-left bg-gradient-to-b from-black/0 via-black/75 to-black/100">
        <Text className="text-white w-9/12 items-left mt-40 mb-5.5 text-2xl" variant="h3">
        Gems Accepted. Thank You!
        </Text>
        <Text className="text-white w-9/12 items-left mb-5.5 text-sm" variant="body-2">
        </Text>
        <Button
            onClick={onClose}
            className="w-9/12 mt-2"
            wrapperClass="py-[10px] bg-blue-gr group-active/button:bg-white-gr"
          >
            <Text
              className="transition-all duration-150 text-white group-active/button:text-black"
              textColor="white"
              variant="btn-1"
            >
              {config?.success_text || 'Message me now'}
            </Text>
        </Button>
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div className="absolute bottom-0 inset-x-0 flex flex-col items-center justify-center p-5 text-left bg-gradient-to-b from-black/0 via-black/75 to-black/100">
        <Text className="text-white w-8/12 items-left mt-40 mb-5.5 text-2xl" variant="h3">
        {config?.currency === 'dollar' ? 'Unlocking...' : 'Sending Gems...'}
        </Text>
        <Text className="text-white w-8/12 items-left mb-5.5 text-sm" variant="body-2">
        Calling {character.name}. Please wait.
        </Text>

        <div className="py-[10px] w-8/12 items-center">
          <Player autoplay loop src={dotsAnimation} style={{ height: '40px', width: '80px' }} />
        </div>
      </div>
    );
  };

  const renderDefault = () => {
    return (
      <div className="absolute bottom-0 inset-x-0 flex flex-col items-center justify-center p-5 text-left bg-gradient-to-b from-black/0 via-black/75 to-black/100">
        <Text className="text-white w-9/12 items-left mt-40 mb-5.5 text-2xl" variant="h3">
        {config?.text || 'Wanna keep chatting?'}
        </Text>
        <Text className="text-white w-9/12 items-left mb-5.5 text-sm" variant="body-2">
        {config?.subtitle || 'Send me some 💎 so we can continue the fun'}
        </Text>

        {config?.cost && (
          <UnlockButton
            className="w-9/12 mt-2 items-center"
            wrapperClassName="py-[12px]"    
            onClick={onUnlock}
            currency={config?.currency}
            title={config?.button_text || 'Send'}
            amount={config?.cost}
          />
        )}
      </div>
    );
  };

  const renderSubscriptionRequired = () => {
    return (
      <div className="flex flex-col h-full items-center justify-end sm:justify-between p-5 text-left">
        <div className="flex flex-col items-left justify-self-end mt-5 mb-8">
          {onRenderBenefit ? onRenderBenefit() : renderSubscriptionBenefit()}
        </div>

          <div className="flex w-full items-center justify-center mb-4">
            {config?.subscription_required && (
              <UnlockButton
                className="w-9/12 items-center"
                wrapperClassName="py-[12px]"    
                onClick={onUnlock}
                currency={config?.currency}
                title={config?.button_text || 'Send'}
                amount={config?.cost}
              />
            )}
          </div>
      </div>
    );
  };

  return (
    <BaseModal
      className={cn("h-full overflow-x-hidden overflow-y-scroll sm:h-auto sm:overflow-auto", className)} 
      id={idOverride || Modals.UnlockMessage}
    >
      <div className="flex flex-col items-center justify-center w-full h-full">
        <div className="flex flex-col sm:flex-row items-center justify-center relative bg-black-100 rounded-3xl border border-gray-700 overflow-hidden h-[720px] sm:h-[520px] max-w-[calc(100vw-20px)] max-h-[calc(100vh-20px)]">

          <div className="w-[400px] max-w-full h-[300px] flex sm:hidden">
            {
              character.avatar_url && (
                <Image
                  src={character.avatar_url}
                  alt={character.name || ''}
                  layout="fill"
                  objectFit="cover"
                  className="w-full h-full object-top"
                />
              )
            }

            <div className="absolute bottom-0 inset-x-0 flex flex-col w-full h-full items-center justify-center bg-gradient-to-b from-black/0 via-black/75 to-black/100">
            </div>
          </div>

          <div className="relative w-[400px] max-w-full h-full hidden sm:flex">
            {
              character.avatar_url && (
                <Image
                  src={character.avatar_url}
                  alt={character.name || ''}
                  layout="fill"
                  objectFit="cover"
                  className="w-full h-full object-top"
                />
              )
            }

            <div className="absolute bottom-0 inset-x-0 flex flex-col w-full items-center justify-center p-5 bg-gradient-to-b from-black/0 via-black/75 to-black/100">
              <Text className="text-white w-full items-center justify-center mt-40 mb-8 text-2xl text-center" variant="h3">
              {config?.text || 'Wanna keep chatting?'}
              </Text>
            </div>
          </div>

          <div className="relative w-[400px] max-w-full h-full">

            {config?.subscription_required && renderSubscriptionRequired()}
            {!loading && state === 'default' && !config?.subscription_required && renderDefault()}
            {!loading && state === 'success' && renderSuccess()}
            {loading && renderLoading()}

          </div>

          <div className="absolute bottom-0 items-center justify-center">
            <Text className={cn(`mt-2 text-white/50 whitespace-nowrap`)} variant="h6">
            {timeLeft ? timeStr : ''}
            </Text>
          </div>

          <button
            onClick={onClose}
            className="absolute right-2 top-2 rounded-full bg-black/[0.5] border border-white/[0.5] p-1 ring-offset-background transition-opacity hover:opacity-100 hover:bg-white/[0.5] disabled:pointer-events-none">
            <CloseIcon />
          </button>

        </div>
      </div>
      {children}
    </BaseModal>
  );
};
